<template>
  <input
    ref="addressRef"
    :value="modelValue"
    type="text"
    class="form-control form-control-sm"
  />
</template>

<script>
import { onMounted, ref } from "@vue/composition-api";

export default {
  emits: ["placeChanged"],
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const addressRef = ref(null);

    onMounted(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        addressRef.value,
        {
          types: ["address"],
          //   fields: ['address_components', 'formatted_address', 'place_id', 'geometry'],
          componentRestrictions: { country: "sg" },
        }
      );

      google.maps.event.addListener(autocomplete, "place_changed", () => {
        console.log(autocomplete.getPlace());

        emit("place-changed", autocomplete.getPlace());
      });
    });

    return {
      addressRef,
    };
  },
};
</script>
